<template>
  <b-card>
    <h3>Let's Post Something New</h3>
    <hr />
    <b-tabs fill>
      <b-tab title="Post" variant="primary">
        <b-card-text>
          <b-form-textarea
            v-model="post"
            placeholder="What's on your mind?"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
          <b-button
            variant="primary"
            class="mt-1"
            @click="createPost"
            :disabled="isLoading"
          >
            <b-spinner
              v-if="isLoading"
              small
              label="Posting..."
              variant="primary"
            ></b-spinner>
            <span v-else>Post</span>
          </b-button>
        </b-card-text>
      </b-tab>
      <b-tab title="Image" variant="warning">
        <b-card-text>
          <b-form-file
            v-model="file"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            accept="image/*"
            class="mt-1"
          ></b-form-file>
          <b-button
            variant="primary"
            class="mt-1"
            @click="createPost"
            :disabled="isLoading"
          >
            <b-spinner
              v-if="isLoading"
              small
              label="Posting..."
              variant="primary"
            ></b-spinner>
            <span v-else>Post</span>
          </b-button>
        </b-card-text>
      </b-tab>
      <b-tab title="Youtube Video">
        <b-card-text>
          <b-form-input
            v-model="post"
            placeholder="Paste Youtube Video Link"
          ></b-form-input>
          <b-button
            variant="primary"
            class="mt-1"
            @click="createPost"
            :disabled="isLoading"
          >
            <b-spinner
              v-if="isLoading"
              small
              label="Posting..."
              variant="primary"
            ></b-spinner>
            <span v-else>Post</span>
          </b-button>
        </b-card-text>
      </b-tab>
      <b-tab title="Public Meeting">
        <b-card-text>
          <b-form-input
            v-model="post"
            placeholder="Meeting Title"
          ></b-form-input>
          <b-button
            variant="primary"
            class="mt-1"
            @click="createPost"
            :disabled="isLoading"
          >
            <b-spinner
              v-if="isLoading"
              small
              label="Posting..."
              variant="primary"
            ></b-spinner>
            <span v-else>Post</span>
          </b-button>
        </b-card-text>
      </b-tab>
    </b-tabs>
  </b-card>
</template>
<script>
import {
  BRow,
  BCol,
  BForm,
  BFormInput,
  BCard,
  BButton,
  BTabs,
  BTab,
  BCardText,
  BFormTextarea,
  BFormFile,
} from "bootstrap-vue";
export default {
  name: "NewPost",
  components: {
    BFormFile,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BCard,
    BButton,
    BTabs,
    BTab,
    BCardText,
    BFormTextarea,
  },
  data() {
    return {
      post: "",
      isLoading: false,
    };
  },
  methods: {
    async createPost() {
      try {
        this.isLoading = true;
        const formData = new FormData();
        formData.append("post", this.post);
        const { data } = await axios.post("/posts/create", formData);
        this.isLoading = false;
        this.post = "";
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
