<template>
  <div class="sidebar-detached sidebar-left mt-0">
    <div class="sidebar">
      <div class="sidebar-shop show">
        <!-- Filters' Card -->
        <b-card class="p-0 rounded-0" no-body>
          <b-skeleton-wrapper :loading="isPreloading">
            <template #loading>
              <div class="p-1">
                <b-skeleton height="5" />
                <b-skeleton height="5" />
                <b-skeleton height="5" />
              </div>
              <div class="p-1 border-top">
                <b-skeleton height="5" />
                <b-skeleton height="5" />
                <b-skeleton height="5" />
              </div>
              <div class="p-1 border-top">
                <b-skeleton height="5" />
                <b-skeleton height="5" />
                <b-skeleton height="5" />
              </div>
            </template>

            <!-- Multi Range -->
            <div class="profile-types pt-1 pl-1 pr-1">
              <h5 class="card-title filter-title mt-0">{{ $t("Timeline.Profile Type") }}</h5>
              <b-form-radio-group
                class="price-range-defined-radio-group"
                stacked
                :options="filtersData.profileTypes"
                v-model="profileType"
                :data-value="profileType.value"
              />
            </div>
            <hr v-if="filtersData.profileTypes" class="mt-0" />
            <!-- Categories -->
            <div class="categories" v-if="profileType">
              <div class="pl-1 pr-1">
                <h5 class="filter-title card-title">
                  <strong>{{ $t("Timeline.Categories") }}</strong>
                </h5>
                <b-form-radio-group
                  class="categories-radio-group"
                  stacked
                  :options="filtersData.categories"
                  v-model="category"
                />
              </div>
              <hr />
            </div>

            <!-- Categories -->
            <div class="countries pl-1 pr-1 border-bottom pb-1">
              <h5 class="filter-title card-title">{{ $t("Timeline.Countries") }}</h5>
              <treeselect
                :placeholder="$t('Timeline.All Countries')"
                v-model="countries"
                :options="filtersData.countries"
                :loadOptions="loadOptions"
                multiple
              />
            </div>

            <div class="blockchainExchange p-1">
              <h5 class="filter-title card-title">{{ $t("Timeline.Blockchain Exchange") }}</h5>
              <b-form-radio-group
                class="categories-radio-group"
                stacked
                :options="filtersData.blockchainExchange"
                v-model="blockchainExchange"
              />
            </div>
          </b-skeleton-wrapper>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormRadioGroup,
  BLink,
  BSkeletonWrapper,
  BSkeleton,
} from "bootstrap-vue";
import Treeselect from "@riophae/vue-treeselect";
import useTimeline from "./useTimeline";
export default {
  components: {
    BRow,
    BCol,
    BFormRadioGroup,
    BLink,
    BCard,
    BSkeletonWrapper,
    BSkeleton,

    // 3rd Party
    Treeselect,
  },
  props: {
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const {
      filtersData,
      getFiltersData,
      profileType,
      category,
      countries,
      blockchainExchange,
      isPreloading,
    } = useTimeline();
    getFiltersData();

    const loadOptions = async (query) => {
      return filtersData.countries;
    };

    return {
      filtersData,
      profileType,
      category,
      countries,
      blockchainExchange,
      loadOptions,
      isPreloading,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-slider.scss";
</style>

<style lang="scss" scoped>
.sidebar {
  position: relative;
}
.sidebar-shop {
  position: fixed;
  width: 260px;
}
.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
  ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}
@media only screen and (max-width: 600px) {
  .sidebar-shop {
    display: none;
  }
}
</style>
