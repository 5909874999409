<template>
  <b-card :title="title" class="border-0 mb-0 rounded-0" body-class="border-bottom">
    <b-skeleton-wrapper :loading="profiles.length == 0">
      <template #loading>
        <b-skeleton height="30" />
        <b-skeleton height="30" />
        <b-skeleton height="30" />
      </template>
      <div
        v-for="(data, index) in profiles"
        :key="data.avatar"
        class="d-flex justify-content-start align-items-center"
        :class="index == 0 ? 'mt-2' : 'mt-1'"
      >
        <div class="user-page-info">
          <b-link :to="`${data.profileType}/details/${data._id}`"
            ><h6 class="mb-0 text-bold">
              {{ data.name }}
            </h6></b-link
          >
        </div>
      </div>
    </b-skeleton-wrapper>
  </b-card>
</template>
<script>
import { BCard, BLink, BSkeleton, BSkeletonWrapper } from "bootstrap-vue";
export default {
  components: {
    BCard,
    BLink,
    BSkeleton,
    BSkeletonWrapper,
  },
  data() {
    return {};
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    profiles: {
      type: Array,
      required: true,
    },
  },
};
</script>
