<template>
  <div class="timeline-sidebar-right">
    <trending-card
      v-if="trendingIdeas"
      :title="$('Timeline.Trending Ideas')"
      :profiles="trendingIdeas"
    />
    <trending-card
      v-if="trendingProducts"
      :title="$t('Timeline.Trending Products')"
      :profiles="trendingProducts"
    />
    <trending-card
      v-if="trendingProjects"
      :title="$t('Timeline.Trending Projects')"
      :profiles="trendingProjects"
    />
    <trending-card
      v-if="trendingServices"
      :title="$t('Timeline.Trending Services')"
      :profiles="trendingServices"
    />

    <b-card class="rounded-0 border-0">
      <h5>{{ $t("Timeline.Who to follow") }}</h5>
      <b-skeleton-wrapper :loading="whoToFollow.length == 0">
        <template #loading>
          <div
            class="d-flex align-items-center justify-content-center mb-1"
            v-for="(x, i) in [1, 2, 3]"
            :key="`placeholder${i}`"
          >
            <div class="mr-50">
              <b-skeleton size="lg" type="avatar" />
            </div>
            <div class="user-page-info" style="flex-grow: 1">
              <b-skeleton height="35" width="100%" class="mb-1" />
              <b-skeleton height="10" width="80%" class="mb-0" />
            </div>
          </div>
        </template>
        <div
          v-for="(data, index) in whoToFollow"
          :key="data._id"
          class="d-flex justify-content-start align-items-center"
          :class="index == 0 ? 'mt-2' : 'mt-1'"
        >
          <b-link :to="`/profile/${data.username}`"
            ><b-avatar
              :src="data.profileImage"
              class="mr-50"
              :class="{ 'bg-white': data.profileImage != 'false' }"
              size="40"
          /></b-link>
          <div class="user-page-info">
            <b-link :to="`/profile/${data.username}`"
              ><h6 class="mb-0">
                {{ data.username }}
              </h6></b-link
            >
            <small class="text-muted">{{ $t(`profile.${data.userType}`) }}</small>
          </div>
        </div>
      </b-skeleton-wrapper>
    </b-card>
  </div>
</template>
<script>
import { BCard, BAvatar, BButton, BLink, BSkeletonWrapper, BSkeleton } from "bootstrap-vue";
import TrendingCard from "./TrendingCard.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    BCard,
    BAvatar,
    BButton,
    TrendingCard,
    BLink,
    BSkeletonWrapper,
    BSkeleton,
  },

  computed: {
    ...mapGetters({
      trendingIdeas: "timeline/getTrendingIdeas",
      trendingServices: "timeline/getTrendingServices",
      trendingProjects: "timeline/getTrendingProjects",
      trendingProducts: "timeline/getTrendingProducts",
      whoToFollow: "timeline/getWhoToFollow",
    }),
  },
  methods: {
    capatilized: function (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
  data() {
    return {
      suggestions: [
        {
          avatar: "/img/avatar-s-9.397f0acd.jpg",
          name: "Test Profile 1",
          mutualFriend: "company",
        },
      ],
    };
  },
};
</script>
<style scoped>
.timeline-sidebar-right {
  min-width: 280px;
  max-width: 280px;
  position: fixed;
  right: 0px;
  top: 79px;
  background: #fff;
  border-left: 1px solid rgba(0, 0, 0, 0.09);
  height: calc(100% - 79px);
  overflow: auto;
}
</style>
