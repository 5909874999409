<template>
  <b-modal
    id="reactionsmodal"
    scrollable
    title="Reactions on this post"
    cancel-title="Close"
    hide-footer
    centered
    class="border"
  >
    <div class="alert alert-danger p-1" v-if="error">{{ error }}</div>
    <div id="reactions">
      <b-media class="pb-1" v-for="react in reactions" :key="react._id">
        <template #aside>
          <b-avatar size="lg" :src="react.react.userId.profileImage">
            <template #badge> <feather-icon icon="ThumbsUpIcon" /> </template>
          </b-avatar>
        </template>
        <b-link target="_blank" :href="`/profile/${react.react.userId.username}`">{{
          react.react.userId.username
        }}</b-link>
        <div class="text-muted">{{ react.react.userId.userType }}</div>
      </b-media>
    </div>
    <div class="text-center text-muted py-3" v-if="reactions.length < 1 && !loading">
      Sorry! no reactions on this post
    </div>
    <b-button
      @click="loadReactions"
      v-if="!error && loadedReactions < totalReactions"
      :disabled="loading"
      variant="link"
      block
      >{{ loading ? $t("Timeline.Loading") : $t("Timeline.Load more") }}</b-button
    >
  </b-modal>
</template>
<script>
import { BModal, BCard, BCardBody, BMedia, BAvatar, BButton } from "bootstrap-vue";
import axios from "axios";
export default {
  name: "Reactions Modal",
  components: {
    BModal,
    BCard,
    BCardBody,
    BMedia,
    BAvatar,
    BButton,
  },
  data() {
    return {
      reactions: [],
      totalReactions: 0,
      loadedReactions: 0,
      currentPage: 1,
      perPage: 10,
      error: "",
      loading: false,
    };
  },
  props: {
    profileId: {
      type: String,
      required: false,
    },
  },
  methods: {
    loadReactions: async function () {
      try {
        this.loading = true;
        const formData = new FormData();
        formData.append("profileId", this.profileId);
        formData.append("page", this.currentPage);
        formData.append("perPage", this.perPage);
        const { data } = await axios.post("/users/profileReacts/list", formData);
        this.totalReactions = data.total;
        this.reactions = [...this.reactions, ...data.data.reactings];
        this.loadedReactions += data.data.reactings.length;
        this.currentPage += 1;
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.error = e.message;
      }
    },
  },
  mounted() {
    this.$root.$on("bv::modal::shown", (bvEvent, modalId) => {
      this.reactions = [];
      this.totalReactions = 0;
      this.loadedReactions = 0;
      this.currentPage = 1;
      this.loadReactions();
    });

    this.$root.$on("bv::modal::hidden", (bvEvent, modalId) => {
      this.reactions = [];
      this.totalReactions = 0;
      this.loadedReactions = 0;
      this.currentPage = 1;
    });
  },
};
</script>
